/*-------------------------Actual APIs-------------------------*/
const backendApi = "/api";
const signInUrl = `/auth/sign_in?post_sign_in_url=https%3A%2F%2F`;
const signOutUrl = `/auth/sign_out`;
const authUrl = `${backendApi}/isauthenticated`;
const userInfoUrl = `${backendApi}/userinfo?post_sign_in_url=https%3A%2F%2F`;
const authoriseUrl = `${backendApi}/isauthorised?post_sign_in_url=https%3A%2F%2F`;
const demoAppUrl = `${backendApi}/demoApp`;
const masterAppUrl = `/master/demoApp`;

const messages = {
  workInProgressMessage: "Your content here.",
  authenticatingUserMessage: "Authenticating user",
  loginPrimaryText: "Log in to find out more!",
  UnauthorisedMessage:
    "You don't have access to this app. Please contact support for the access.",
};

const hardCoded = {
  homeLocation: "https://data.reckitt.com/",
  appName: "Base App",
};

const api = {
  authUrl,
  userInfoUrl,
  signInUrl,
  signOutUrl,
  demoAppUrl,
  masterAppUrl,
  authoriseUrl,
};

const config = {
  footerText: "Powered by RBOne",
  api,
  messages,
  hardCoded,
};

export { config };
